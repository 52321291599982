import { render, staticRenderFns } from "./TwitterAuthorization.vue?vue&type=template&id=130486da&scoped=true"
import script from "./TwitterAuthorization.vue?vue&type=script&lang=js"
export * from "./TwitterAuthorization.vue?vue&type=script&lang=js"
import style0 from "./TwitterAuthorization.vue?vue&type=style&index=0&id=130486da&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "130486da",
  null
  
)

export default component.exports